document.addEventListener("DOMContentLoaded", () => {
  const screenshotSection = document.querySelector(".screenshot-section");

  if (screenshotSection !== null) {
    const headerHeight = document.querySelector("header").clientHeight;
    const screenshotAnchors = document.querySelectorAll(".screenshot");
    const screenshot = document.querySelector(".image-column img");
    let intersect = false;

    screenshotAnchors.forEach(elem => {
      const sidebarMenuItemHeight = elem.clientHeight;

      window.addEventListener("scroll", () => {
        const offsetTop =
          elem.getBoundingClientRect().top + window.scrollY - headerHeight * 4;
        const offsetBottom =
          elem.getBoundingClientRect().top +
          window.scrollY -
          headerHeight * 4 +
          sidebarMenuItemHeight;

        const deltaTop = (offsetTop - window.scrollY).toFixed(0);
        const deltaBottom = (offsetBottom - window.scrollY).toFixed(0);

        if (deltaTop < 0) {
          // беру урл и заменяю
          const newUrl = elem.querySelector(".screenshot-image img").src;
          screenshot.src = newUrl;

          // добавляю обводку
          elem.classList.add("active");

          intersect = true;
        } else if (deltaTop < 0 && deltaBottom < 0) {
          intersect = false;
        }

        if (
          (deltaTop < 0 && deltaBottom < 0) ||
          (deltaTop > 0 && deltaBottom > 0)
        ) {
          // для того, чтобы удалять только когда доскролили, а не по событию скролла
          if (intersect) {
            elem.classList.remove("active");
          }
        }
      });
    });
  }
});
