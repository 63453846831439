import $ from "jquery";
import "slick-carousel";
$(document).ready(function() {
  let windowsize = $(window).width();

  $(window).resize(function() {
    windowsize = $(window).width();
  });

  if (windowsize < 600) {
    let button_html = $(".title-cta").html();
    $(".title-cta").remove();
    $(".swiper_part_right").after(button_html);
  }

  $(".swiper").slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000
  });
});
