document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".chrome-plugin") !== null) {
    //график
    const dot = document.getElementById("dot");
    const ordersGroup = document.querySelector("svg .orders");
    const bars = ordersGroup.querySelectorAll(".bar > rect:not(.bg)");
    const barsGroups = ordersGroup.querySelectorAll(".bar");
    const totalLength = 350;
    let xCoords = [];
    const yCoords = [
      62,
      62,
      62,
      62,
      62,
      82,
      62,
      68,
      92,
      92,
      -10,
      -10,
      92,
      92,
      71,
      71,
      71,
      71,
      71,
      46,
      46,
      56,
      60,
      110,
      110,
      110,
      110,
      83,
      83,
      83
    ];

    //цифры над графиком
    const dates = [
      "15 ноя",
      "14 ноя",
      "13 ноя",
      "12 ноя",
      "11 ноя",
      "10 ноя",
      "9 ноя",
      "8 ноя",
      "7 ноя",
      "6 ноя",
      "5 ноя",
      "4 ноя",
      "3 ноя",
      "2 ноя",
      "1 ноя",
      "31 окт",
      "30 окт",
      "29 окт",
      "28 окт",
      "27 окт",
      "26 окт",
      "25 окт",
      "24 окт",
      "23 окт",
      "22 окт",
      "21 окт",
      "20 окт",
      "19 окт",
      "18 окт",
      "17 окт"
    ];

    const prices = [
      "6 199 ₽",
      "6 199 ₽",
      "6 199 ₽",
      "6 199 ₽",
      "6 199 ₽",
      "5 999 ₽",
      "6 199 ₽",
      "6 099 ₽",
      "5 890 ₽",
      "5 890 ₽",
      "Не было на складе",
      "Не было на складе",
      "5 890 ₽",
      "5 890 ₽",
      "6 150 ₽",
      "6 150 ₽",
      "6 150 ₽",
      "6 150 ₽",
      "6 150 ₽",
      "6 579 ₽",
      "6 579 ₽",
      "6 399 ₽",
      "6 299 ₽",
      "4 999 ₽",
      "4 999 ₽",
      "4 999 ₽",
      "4 999 ₽",
      "5 800 ₽",
      "5 800 ₽",
      "5 800 ₽"
    ];

    let ordersQuantity = [];
    const chartCard = document.querySelector(".card .flex-group");

    bars.forEach(element => {
      const calcNumber =
        (totalLength * Number(element.getAttribute("x").slice(0, -1))) / 100;
      const quantity = Number(element.getAttribute("height")) * 11 - 11;
      xCoords.push(calcNumber);
      ordersQuantity.push(quantity);
    });

    barsGroups.forEach(element => {
      element.addEventListener("mouseover", () => {
        const elIndex = Array.from(element.parentNode.children).indexOf(
          element
        );
        //перемещение точки
        dot.setAttribute(
          "transform",
          `translate(${xCoords[elIndex] + 6}, ${yCoords[elIndex]})`
        );

        //подстановка цифр
        if (prices[elIndex] !== "Не было на складе") {
          chartCard.innerHTML = `
            <div class="group date"><span>${dates[-elIndex + 29]}</span></div>
            <div class="group price"><span>Цена: ${prices[elIndex]}</span></div>
            <div class="group orders"><span>Заказы: ${
              ordersQuantity[elIndex]
            } шт</span></div>
          `;
        } else {
          chartCard.innerHTML = `
            <div class="group date"><span>${dates[-elIndex + 29]}</span></div>
            <div class="group price"><span>${prices[elIndex]}</span></div>
          `;
        }
      });

      element.addEventListener("mouseout", () => {
        chartCard.innerHTML = `
          <div class="group date"><span>${dates[0]}</span></div>
          <div class="group price"><span>Цена: ${prices[29]}</span></div>
          <div class="group orders"><span>Заказы: ${
            ordersQuantity[29]
          } шт</span></div>
        `;
      });
    });
  }
});
