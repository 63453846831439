const toggleClients = document.querySelector("#toggle-clients");
let isClientsShown = false;

if (toggleClients !== null) {
  toggleClients.addEventListener("click", e => {
    if (!isClientsShown) {
      document.querySelector(".show-first-12-clients").classList.remove("fade");
      document.querySelector(".show-first-12-clients").classList.add("expand");
      isClientsShown = true;
      e.target.textContent = "Свернуть";
    } else {
      document
        .querySelector(".show-first-12-clients")
        .classList.remove("expand");
      document.querySelector(".show-first-12-clients").classList.add("fade");
      isClientsShown = false;
      e.target.textContent = "Развернуть";
    }
  });
}
