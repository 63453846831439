import $ from "jquery";

export function registerYM() {
  if (!ym) return;

  $(".link-extension-ym").on("click", function(e) {
    ym(65686081, "reachGoal", "link_extension");
  });

  $(".link-automator-ym").on("click", function(e) {
    ym(65686081, "reachGoal", "click-automator-promo");
  });

  $(".link-schedule-demo-ym").on("click", function(e) {
    ym(65686081, "reachGoal", "click_tg_zakazdemo");
  });
}
