import $, { each } from "jquery";

const ready = function() {
  const tableInfoBars = () => {
    const salesK = 0.11;

    $(".table-info-bar.sales").each(function() {
      var value = $(this)
        .find("span")
        .text();
      $(this)
        .find(".bar")
        .css("width", value * salesK + "%");
    });

    const proceedsK = 0.000055;
    $(".table-info-bar.proceeds").each(function() {
      var value = $(this)
        .find("span")
        .text();
      $(this)
        .find(".bar")
        .css("width", value * proceedsK + "%");

      var formattedValue = value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      $(this)
        .find("span")
        .text(formattedValue);
    });
    const shareK = 4.1;
    $(".table-info-bar.share").each(function() {
      var value = $(this)
        .find("span")
        .text();
      $(this)
        .find(".bar")
        .css("width", value * shareK + "%");
      var formattedValue = value.toString().replace(".", ",");
      $(this)
        .find("span")
        .text(formattedValue);
    });

    $(".td.profit").each(function() {
      var value = $(this)
        .find("span")
        .text();
      var formattedValue = value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      $(this)
        .find("span")
        .text(formattedValue);
    });
  };

  tableInfoBars();
};

$(document).ready(ready);
