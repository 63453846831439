const inputRadio = document.querySelectorAll("input[name=paid-period]");

if (inputRadio !== null) {
  inputRadio.forEach(elem => {
    elem.addEventListener("change", () => {
      const discountPersent = Number(elem.value);
      const priceElement = document.querySelectorAll(".plan-card .price");

      priceElement.forEach(elem => {
        const price = Number(elem.dataset.price);
        const discountPrice = price - (discountPersent / 100) * price;
        const priceLabel = elem.querySelector("span");
        const discountLabel = elem.querySelector(".discount");

        priceLabel.innerHTML = ` ${discountPrice.toLocaleString("fr")} ₽/мес.`;

        if (discountLabel) {
          discountLabel.innerHTML = discountPersent
            ? `${price.toLocaleString("fr")} ₽/мес.`
            : "";
        }
      });
    });
  });
}
