/**
 * @param {Array<IntersectionObserverEntry>} entries
 */
function changeSleepState(entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.remove("sleep");
    } else {
      entry.target.classList.add("sleep");
    }
  });
}

export function registerAwakeness() {
  const observer = new IntersectionObserver(changeSleepState);
  const awakenessElements = document.querySelectorAll(".awakeness");

  for (const element of awakenessElements) {
    observer.observe(element);
  }
}
