export function registerModileNavigation() {
  const closeIcon = document.querySelector(".close-icon");
  const burderIcon = document.querySelector(".burger-icon");
  const sidebar = document.querySelector(".mobile-nav-sidebar");
  const mobileNav = document.querySelector(".mobile-nav");

  if (mobileNav !== null) {
    closeIcon.addEventListener("click", event => {
      event.preventDefault();

      sidebar.classList.remove("visible");
    });

    burderIcon.addEventListener("click", event => {
      event.preventDefault();
      event.stopPropagation();

      sidebar.classList.add("visible");
    });

    sidebar.addEventListener("click", event => {
      event.stopPropagation();
    });

    window.addEventListener("click", () => {
      sidebar.classList.remove("visible");
    });
  }
}
