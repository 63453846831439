import Plyr from "plyr";

let registered = new Set();

/**
 * @param {String | undefined} id
 * @param {HTMLElement} element
 */
function register(id, element) {
  if (id) {
    registered.add(id);
  }

  new Plyr(element, {
    controls: ["play", "progress", "current-time", "volume", "fullscreen"]
  });
}

/**
 * @param {String} observableSelector
 * @param {String} playerSelector
 */
export function registerLazyPlayer(observableSelector, playerSelector) {
  const observableElement = document.querySelector(observableSelector);
  const playerElement = document.querySelector(playerSelector);

  if (!observableElement || !playerElement) {
    return;
  }

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting && !registered.has(playerSelector)) {
        register(playerSelector, playerElement);
      }
    });
  });

  observer.observe(observableElement);
}

/**
 * @param {String} playersSelector
 */
export function registerInstantPlayers(playersSelector) {
  const playersElements = document.querySelectorAll(playersSelector);

  if (playersElements.length === 0) {
    return;
  }

  playersElements.forEach(playerElement => {
    register(undefined, playerElement);
  });
}
