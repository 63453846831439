const buyAcademyButton = document.querySelectorAll("a[data-name]");

if (buyAcademyButton !== null) {
  const pricingPopup = document.querySelectorAll(".modal-popup");
  const closeButtons = document.querySelectorAll(".close-button");

  buyAcademyButton.forEach(elem => {
    elem.addEventListener("click", e => {
      e.preventDefault();

      const planName = elem.getAttribute("data-name");

      document.querySelector("body").style.overflow = "hidden";

      pricingPopup.forEach(elem => {
        if (elem.getAttribute("data-name") === planName) {
          elem.classList.add("show");
        }
      });
    });
  });

  closeButtons.forEach(elem => {
    elem.addEventListener("click", () => {
      pricingPopup.forEach(elem => {
        elem.classList.remove("show");
      });
      document.querySelector("body").style.overflow = "unset";
    });
  });

  pricingPopup.forEach(elem => {
    elem.addEventListener("click", e => {
      if (
        !e.target.parentNode.classList.contains("iframe-wrapper") &&
        !e.target.classList.contains("iframe-wrapper")
      ) {
        elem.classList.remove("show");
        document.querySelector("body").style.overflow = "unset";
      }
    });
  });

  document.addEventListener("keydown", e => {
    e = e || window.event;
    var isEscape = false;
    if ("key" in e) {
      isEscape = e.key === "Escape" || e.key === "Esc";
    } else {
      isEscape = e.keyCode === 27;
    }
    if (isEscape) {
      pricingPopup.forEach(elem => {
        elem.classList.remove("show");
      });
      document.querySelector("body").style.overflow = "unset";
    }
  });
}
