import Masonry from "masonry-layout";

const pubs = document.querySelector(".publications");

// создание рубрик на странице рубрики
if (pubs !== null) {
  if (pubs.classList.contains("select-news")) {
    const notNews = pubs.querySelectorAll(".publication-promo:not(.news)");
    notNews.forEach(element => {
      element.remove();
    });
  } else if (pubs.classList.contains("select-vacancy")) {
    const notVacancy = pubs.querySelectorAll(
      ".publication-promo:not(.vacancy)"
    );
    notVacancy.forEach(element => {
      element.remove();
    });
  } else if (pubs.classList.contains("select-articles")) {
    const notArticles = pubs.querySelectorAll(
      ".publication-promo:not(.articles)"
    );
    notArticles.forEach(element => {
      element.remove();
    });
  }
}

const publicationHeader = document.querySelector(".publication-header");

// создание публикаций по теме
if (
  pubs !== null &&
  publicationHeader !== null &&
  publicationHeader.hasAttribute("data-topic")
) {
  if (publicationHeader.dataset.topic.length !== 0) {
    const topicList = publicationHeader.dataset.topic.split(", ");
    const publicationList = pubs.querySelectorAll(".publication-promo");

    publicationList.forEach(elem => {
      // если топик лист elem частично совпадает с топиклистом статьи, оставлять, если нет — удалять ↓
      if (elem.dataset.topic) {
        const elemTopicList = elem.dataset.topic.split(", ");
        const found = elemTopicList.some(item => topicList.includes(item));
        if (!found) {
          elem.remove();
        }
      } else {
        elem.remove();
      }
    });
  }
}

let msnry;
const masonryGrid = document.querySelectorAll(".masonry-grid");

masonryGrid.forEach(elem => {
  msnry = new Masonry(elem, {
    columnWidth: ".grid-sizer",
    itemSelector: ".publication-promo",
    percentPosition: true,
    gutter: 5
  });
});
